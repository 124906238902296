import { addressAboyeur } from "@whitelabel-webapp/address/shared/config";
import { CustomerAddress } from "@whitelabel-webapp/address/shared/models";
import { Customer } from "@whitelabel-webapp/authentication/shared/models";
import {
  Item as CatalogItem,
  ItemResponse,
} from "@whitelabel-webapp/catalog/shared/models";
import {
  createAuthenticatedAxiosClient,
  getPaymentSecretCookie,
} from "@whitelabel-webapp/authentication/shared/utils";
import { getFingerprintSessionId } from "@whitelabel-webapp/checkout/shared/fingerprint-utils";
import {
  DeliveryMethod,
  Merchant,
} from "@whitelabel-webapp/merchant/shared/models";
/* eslint-disable max-lines */
import { isMobileBrowser } from "@whitelabel-webapp/shared/browser-utils";
import {
  Context,
  ENVS,
  groceriesApiBffURL,
  getIsDevelopment,
} from "@whitelabel-webapp/shared/config";
import {
  CookiesStorage,
  Money,
  Recaptcha,
} from "@whitelabel-webapp/shared/models";
import axios, { AxiosInstance } from "axios";
import { v4 as uuidv4 } from "uuid";

import pkg from "../../../../../package.json";
import { ERRORS, ERROR_CODES } from "./constants";
import { DeliveryFeePayload, DeliveryFeeResponse } from "./deliveryFee";
import { Item as CheckoutItem } from "./item";
import { PaymentMethod } from "./paymentMethod";
import {
  CheckoutResponse,
  DeliveredMethodTag,
  OrderPayload,
  UserOpinionSurveyToggleResponse,
} from "./types";
import { CardTokenResponse } from ".";

export class Order {
  static nextClient: AxiosInstance;
  static client: AxiosInstance;

  static mountHeaders(customer: Customer) {
    const { accountId } = customer.authentication;

    return {
      authority: "wsloja.ifood.com.br",
      "x-ifood-device-payment-secret": getPaymentSecretCookie(),
      account_id: accountId,
    };
  }

  static initClient(merchantId: string): void {
    Order.client = createAuthenticatedAxiosClient(
      groceriesApiBffURL,
      merchantId,
    );
  }

  static initNextClient(): void {
    Order.nextClient = axios.create();
  }

  static async getIsCheckoutEnabled() {
    if (!Order.nextClient) {
      Order.initNextClient();
    }

    const { data } = await Order.nextClient.get<boolean>(
      "/api/checkout_enabled",
    );

    return Boolean(data);
  }

  static async getUserOpinionSurvey(): Promise<UserOpinionSurveyToggleResponse> {
    if (!Order.nextClient) {
      Order.initNextClient();
    }

    const { data } =
      await Order.nextClient.get<UserOpinionSurveyToggleResponse>(
        "/api/user_opinion",
      );

    return data;
  }

  static async getDeliveryFee(payload: DeliveryFeePayload) {
    if (!payload.customer) {
      payload.customer = {
        id: 0,
        uuid: "00000000-0000-0000-0000-000000000000",
        name: "",
        email: "",
        cpf: "",
        phones: [],
        tags: [],
      };
    }

    const { data } = await Order.client.post<DeliveryFeeResponse>(
      `/checkout/v6/order/deliveryfee`,
      payload,
    );

    return data;
  }

  static async createOrder(
    merchantId: string,
    customer: Customer,
    payload: OrderPayload,
  ) {
    if (!Order.client) {
      Order.initClient(merchantId);
    }

    try {
      const headers = Order.mountHeaders(customer);
      const { data: checkoutResponse, headers: checkoutHeadersResponse } =
        await Order.client.post<CheckoutResponse>(`/checkout`, payload, {
          headers,
        });
      return { checkoutResponse, checkoutHeadersResponse };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        return {
          checkoutResponse: error.response.data,
          checkoutHeadersResponse: error.response.headers,
        };
      } else {
        throw error;
      }
    }
  }

  static getPlatform() {
    if (!isMobileBrowser.any()) {
      return "DESKTOP";
    }

    return isMobileBrowser.iOS() ? "IOS" : "ANDROID";
  }

  items: Record<string, CheckoutItem> = {};
  itemsList: CheckoutItem[] = [];

  address?: CustomerAddress;
  customer?: Customer;

  document?: string;
  totalItems: Money = new Money(0);
  totalOrder: Money = new Money(0);
  credit: Money = new Money(0);
  message?: string;

  deliveryFee?: Money;
  deliveryTime?: number;

  benefits?: any;
  campaignCode?: string;

  deliveryMethod?: DeliveryMethod;

  paymentMethod?: PaymentMethod;

  cardToken?: CardTokenResponse;

  deliveryAdditionalInfo?: string;

  constructor(
    public merchant: Merchant,
    public initialItems?: CheckoutItem[],
    public initialCustomerAddress?: CustomerAddress,
    public initialDeliveryMethod?: DeliveryMethod,
    public initialCampaignCode?: string,
    public initialCustomer?: Customer,
    public initialDeliveryAdditionalInfo?: string,
  ) {
    this.withDeliveryMethod(initialDeliveryMethod);
    this.withCampaignCode(initialCampaignCode);
    this.withCustomer(initialCustomer);
    this.withAddress(initialCustomerAddress);
    this.withDeliveryAdditionalInfo(initialDeliveryAdditionalInfo);

    if (!initialItems?.length) {
      return;
    }

    initialItems.forEach((item) => this.withItem(item));
  }

  hasDeliveryMethod() {
    return Boolean(this.deliveryMethod);
  }

  isDefaultDeliveryMethod() {
    return Boolean(
      this.deliveryMethod && this.deliveryMethod.mode === "DELIVERY",
    );
  }

  isTakeoutDeliveryMethod() {
    return Boolean(
      this.deliveryMethod && this.deliveryMethod.mode === "TAKEOUT",
    );
  }

  isPixPaymentMethod() {
    return this.paymentMethod?.isPix();
  }

  isDeliveredByMerchant() {
    return Boolean(
      this.deliveryMethod && this.deliveryMethod.deliveredBy === "MERCHANT",
    );
  }

  isDeliveredByIfood() {
    return Boolean(
      this.deliveryMethod && this.deliveryMethod.deliveredBy === "IFOOD",
    );
  }

  addItem(item: CheckoutItem) {
    const instanceId = item.instanceId || uuidv4();

    item.instanceId = instanceId;
    item.catalogItem.instanceId = instanceId;

    const items = {
      ...this.items,
      [item.instanceId]: item,
    };

    this.items = items;
    this.itemsList = Object.values(items);
  }

  removeItem(item: CheckoutItem) {
    if (this.items[item.instanceId]) {
      delete this.items[item.instanceId];
    }
    this.itemsList = Object.values(this.items);
  }

  removeAllItems() {
    this.items = {};
    this.itemsList = [];
    this.totalItems = new Money(0);
    this.totalOrder = new Money(0);
    this.campaignCode = "";

    return this;
  }

  calculateTotals() {
    const totalItems = this.itemsList.reduce(
      (totalItemsAcc, item) =>
        totalItemsAcc.add(item.calculatePrice(this.merchant)),
      new Money(0),
    );
    this.totalItems = totalItems;

    const itemsAndDeliveryFee = totalItems.add(
      this.deliveryFee ?? new Money(0),
    );
    const isCreditGreater =
      itemsAndDeliveryFee.getValue() < this.credit.getValue();
    this.totalOrder = isCreditGreater
      ? new Money(0)
      : itemsAndDeliveryFee.sub(this.credit);
  }

  hasItems() {
    return Boolean(this.itemsList.length);
  }

  withItem(item: CheckoutItem) {
    const hasItem = Boolean(this.items[item.instanceId]);

    if (hasItem && item.quantity === 0) {
      this.removeItem(item);
    } else if (item.quantity > 0) {
      this.addItem(item);
    }

    this.calculateTotals();

    return this;
  }

  withoutItem(item: CheckoutItem) {
    this.removeItem(item);
    this.calculateTotals();
    return this;
  }

  withDeliveryMethod(deliveryMethod?: DeliveryMethod) {
    if (
      this.deliveryMethod &&
      deliveryMethod &&
      this.deliveryMethod.deliveredBy !== deliveryMethod.deliveredBy
    ) {
      this.paymentMethod = undefined;
    }

    this.deliveryMethod = deliveryMethod;

    return this;
  }

  withAddress(address?: CustomerAddress) {
    this.address = address;

    return this;
  }

  withCustomer(customer?: Customer) {
    this.customer = customer;

    return this;
  }

  async withCampaignCodeValidation() {
    if (!this.campaignCode) {
      throw new Error("Nenhum cupom foi adicionado");
    }

    const newOrder = await this.sendDeliveryFee();

    if (newOrder?.message) {
      throw new Error(newOrder.message);
    }

    this.campaignCode = newOrder.campaignCode;

    return newOrder;
  }

  withCampaignCode(campaignCode?: string) {
    if (!campaignCode) return this.removeCampaignCode();

    this.campaignCode = campaignCode;

    return this;
  }

  removeCampaignCode() {
    this.campaignCode = "";
    this.credit = new Money(0);
    this.calculateTotals();

    return this;
  }

  withDocument(document?: string) {
    this.document = document;

    return this;
  }

  withPaymentMethod(paymentMethod?: PaymentMethod) {
    this.paymentMethod = paymentMethod;

    return this;
  }

  withDeliveryAdditionalInfo(deliveryAdditionalInfo?: string) {
    this.deliveryAdditionalInfo = deliveryAdditionalInfo;

    return this;
  }

  async updateOrCreateCustomerAddress() {
    if (!this.address || this.isTakeoutDeliveryMethod()) {
      return this;
    }
    try {
      this.address = await this.address.updateOrCreate(this.merchant);
    } catch (e: any) {
      addressAboyeur.events.catch.onError(e);
    }

    return this;
  }

  getDeliveredMethodTag(): DeliveredMethodTag {
    if (this.isTakeoutDeliveryMethod()) {
      return "delivered_by_merchant";
    }

    return this.isDeliveredByMerchant()
      ? "delivered_by_merchant"
      : "delivered_by_ifood";
  }

  async toCheckout(): Promise<OrderPayload> {
    if (!this.customer) {
      throw new Error(ERRORS.NO_USER_SELECTED);
    }

    if (this.isDefaultDeliveryMethod() && !this.address) {
      throw new Error(ERRORS.NO_ADDRESS_SELECTED);
    }

    if (!this.deliveryMethod) {
      throw new Error(ERRORS.NO_DELIVERY_METHOD_SELECTED);
    }

    if (!this.paymentMethod) {
      throw new Error(ERRORS.NO_PAYMENT_METHOD_SELECTED);
    }

    if (this.itemsList.length === 0) {
      throw new Error(ERRORS.EMPTY_CART);
    }

    const deliveredMethodTag = this.getDeliveredMethodTag();
    const restaurantOrderItems = this.itemsList.map((item) => item.toPayload());

    return {
      number: -1,
      restaurantOrder: [
        {
          restaurant: { uuid: this.merchant.id },
          itens: restaurantOrderItems,
        },
      ],
      address: this.isDefaultDeliveryMethod()
        ? (this.address as CustomerAddress).toPayload()
        : CustomerAddress.fromMerchantToPayload(this.merchant),
      scheduled: !!this.deliveryMethod.schedule.selectedTimeSlot,
      customer: this.customer.toPayload(),
      paymentSources: this.paymentMethod.toPayload(
        deliveredMethodTag,
        this.totalOrder,
        this.cardToken,
      ),
      deliveryFee: this.deliveryFee?.getValue() ?? 0,
      browser: window.navigator.userAgent,
      source: pkg.name,
      version: pkg.version,
      totalItens: this.totalItems.getValue(),
      totalOrder: this.totalOrder.getValue(),
      credit: this.credit?.getValue() ?? 0,
      cpf: this.document ?? "",
      test: getIsDevelopment() || CookiesStorage.get("x-ifood-test-mode"),
      togo: this.isTakeoutDeliveryMethod(),
      fingerprintId: getFingerprintSessionId(),
      deliveryMethod: this.deliveryMethod.toPayload(),
      context: {
        tags: [deliveredMethodTag],
      },
      replacementOptions: {
        mode: "NOT_APPLICABLE",
      },
      deliveryAdditionalInfo: this.deliveryAdditionalInfo ?? "",
      setupId: ENVS.PAYMENTS_MULTI_SETUP_ID,
      campaignCode: "",
      benefits: this.benefits,
      platform: Order.getPlatform(),
      additionalInfo: {
        metadata: {
          handshake_laas_enabled: this.isDeliveredByIfood()
            ? "true"
            : undefined,
        },
      },
      responseToken: await Recaptcha.getRecaptcha("checkout"),
    };
  }

  toDeliveryFee(): DeliveryFeePayload {
    if (!this.deliveryMethod) {
      throw new Error(ERRORS.NO_DELIVERY_METHOD_SELECTED);
    }

    if (this.isDefaultDeliveryMethod() && !this.address) {
      throw new Error(ERRORS.NO_ADDRESS_SELECTED);
    }

    const deliveryMethod = this.deliveryMethod.toPayload();

    const restaurantOrderItems = this.itemsList.map((item) => item.toPayload());

    return {
      customer: this.customer ? this.customer.toPayload() : null,
      address: this.isDefaultDeliveryMethod()
        ? (this.address as CustomerAddress).toPayload()
        : CustomerAddress.fromMerchantToPayload(this.merchant),
      restaurantOrder: [
        {
          itens: restaurantOrderItems,
          restaurant: {
            uuid: this.merchant.id,
          },
        },
      ],
      scheduled: !!this.deliveryMethod.schedule.selectedTimeSlot,
      paymentSources: null,
      benefitsToken: "",
      deliveryMethod,
      togo: this.isTakeoutDeliveryMethod(),
      campaignCode: this.campaignCode ?? "",
      setupId: ENVS.PAYMENTS_MULTI_SETUP_ID,
    };
  }

  async sendDeliveryFee() {
    if (!Order.client) {
      Order.initClient(this.merchant.id);
    }

    if (!this.hasItems()) return this;

    if (!this.deliveryMethod) {
      throw new Error(ERRORS.NO_DELIVERY_METHOD_SELECTED);
    }

    const deliveryFeePayload = this.toDeliveryFee();

    let deliveryFeeResponse;

    deliveryFeeResponse = await Order.getDeliveryFee({
      ...deliveryFeePayload,
    });

    if (!deliveryFeeResponse) {
      throw new Error(ERRORS.UNEXPECTED);
    }

    const { message, data } = deliveryFeeResponse;

    if (!data?.orderDeliveryFee) {
      throw new Error(message ?? ERRORS.UNEXPECTED);
    }

    this.deliveryFee = new Money(data.orderDeliveryFee.deliveryFee);
    this.deliveryTime = data.orderDeliveryFee.estimatedDeliveryTime;
    this.campaignCode = data.orderDeliveryFee.campaignCode;
    this.credit = new Money(data.orderDeliveryFee.credit);
    this.benefits = data.orderDeliveryFee.benefits;
    this.message = message;

    this.calculateTotals();

    return this;
  }

  withCardToken(cardToken?: CardTokenResponse) {
    this.cardToken = cardToken;

    return this;
  }

  async sendOrder() {
    if (!Order.client) {
      Order.initClient(this.merchant.id);
    }

    if (!this.customer) {
      throw new Error(ERRORS.AUTHENTICATION_REQUIRED);
    }

    const orderPayload = await this.toCheckout();

    const { checkoutResponse, checkoutHeadersResponse } =
      await Order.createOrder(this.merchant.id, this.customer, orderPayload);

    const headerErrorCode =
      checkoutHeadersResponse["X-Ifood-Error-Code"] ??
      checkoutHeadersResponse["x-ifood-error-code"];
    if (headerErrorCode === ERROR_CODES.CHECKOUT_DISABLED) {
      throw new Error(ERRORS.CHECKOUT_DISABLED);
    }

    if (!checkoutResponse) {
      throw new Error(ERRORS.UNEXPECTED);
    }

    const { message, data, items } = checkoutResponse;

    if (items) {
      const catalogItems = items?.filter(Boolean).map(CatalogItem.fromApi);
      throw new ItemsError(
        "Alguns itens mudaram de preço, verifique antes de continuar",
        catalogItems,
      );
    }

    if (!data?.orderCheckout) {
      throw new Error(message ?? ERRORS.UNEXPECTED);
    }

    const { orderCheckout } = data;

    return { orderResponse: orderCheckout, orderPayload };
  }
}
export class ItemsError extends Error {
  catalogItems: CatalogItem[];

  constructor(message: string, catalogItems: any) {
    super(message);
    this.catalogItems = catalogItems;
    this.name = 'ItemsError';
  }
}
