import { maxWidth } from "@app/utils/viewport";
import SVG from "react-inlinesvg";
import styled, { DefaultTheme } from "styled-components";

const bellowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.l.min}`;

export const Footer = styled.footer`
  width: -webkit-fill-available;
`;

export const TopFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${({ theme }) => `${theme.space.looser} ${theme.space.loose}`};

  @media (${bellowDesktop}) {
    padding: ${({ theme }) => `${theme.space.largest} ${theme.space.large}`};
    text-align: center;
  }
`;

export const BottomFooter = styled(TopFooter)`
  text-align: left;
`;

export const Divider = styled.div`
  background-color: #cccccc !important;
  max-width: ${maxWidth};
  margin: 0 auto;
  height: 1px;

  @media (${bellowDesktop}) {
    max-width: ${({ theme }) => `calc(100% - ${theme.space.largest})`};
  }
`;

export const Content = styled.div`
  max-width: ${maxWidth};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space.regular};

  @media (${bellowDesktop}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.space.largest};
  }
`;

export const Column = styled.div`
  flex: auto;
  display: flex;
  gap: ${({ theme }) => theme.space.larger};
  max-width: 33%;
  width: min-content;
  flex-direction: column;

  @media (${bellowDesktop}) {
    width: 100%;
    max-width: 100%;
    gap: ${({ theme }) => theme.space.large};
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.regular};
`;

export const SocialMediaGroupColumn = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.regular};
  flex-direction: row;

  @media (${bellowDesktop}) {
    justify-content: center;
  }
`;

export const SocialMediaIcon = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 500;
  @media (${bellowDesktop}) {
    font-weight: 700;
  }
`;

export const Info = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.s};
  line-height: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-weight: 400;
  margin: 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const SmallInfo = styled(Info)`
  font-size: ${({ theme }) => theme.fontSizes.tsm};
  @media (${bellowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

export const SmallerInfo = styled(Info)`
  max-width: 256px;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  &:hover {
    cursor: inherit;
    text-decoration: none;
  }
`;

export const PharmaInfo = styled.p`
  padding-top: ${({ theme }) => theme.space.looser};
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.grayDarkest};
  text-align: center;
  font-weight: 400;
  width: 100%;
  margin: 0;

  font-size: ${({ theme }) => theme.fontSizes.tsm};
  @media (${bellowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

export const Copyright = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.fontSizes.s};
  font-weight: 400;
  color: #515151;
  margin: 0;

  max-width: 256px;
  @media (${bellowDesktop}) {
    max-width: calc(100% - 145px);
  }
`;

export const PoweredBy = styled(Copyright)`
  white-space: nowrap;
`;

export const LgpdLinks = styled.div`
  flex: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: ${({ theme }) => theme.space.regular};

  @media (${bellowDesktop}) {
    > p {
      text-align: center;
    }
  }
`;

export const Payments = styled.div`
  gap: ${({ theme }) => theme.space.regular};
  grid-template-columns: repeat(auto-fill, minmax(32px, 1fr));
  display: grid;

  @media (${bellowDesktop}) {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const Image = styled(SVG)`
  margin: 0px 3px 0px 3px;
`;
