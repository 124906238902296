import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import * as S from "./styles";
import { usePaymentMethods } from "@app/domains/checkout";
import { getBrandImageURL } from "@app/domains/checkout/views/CheckoutDetails/Status/OrderSummary/components/PaymentSelect/PaymentSelector/utils";
import NextImage from "next/image";
import { useMemo } from "react";
import Link from "next/link";

export const Footer: React.VFC = () => {
  const { merchant } = useMerchant();
  const { paymentMethods } = usePaymentMethods();

  const payments = useMemo(() => {
    const seenUrls = new Set();
    return paymentMethods?.list.filter((paymentMethod) => {
      const brandImageUrl = getBrandImageURL(paymentMethod);
      if (brandImageUrl.includes("default")) return false;
      if (seenUrls.has(brandImageUrl)) return false;
      seenUrls.add(brandImageUrl);
      return true;
    });
  }, [paymentMethods]);

  const socialMediaLinks = merchant.getSocialMediaLinks();
  const institutionalLink = merchant.getInstitutionalLink();

  const getSocialMediaIcons = (title: string) => {
    const icons = {
      Whatsapp: "/icons/social-media/icon-whatsapp.svg",
      Facebook: "/icons/social-media/icon-facebook.svg",
      Telegram: "/icons/social-media/icon-telegram.svg",
      Instagram: "/icons/social-media/icon-instagram.svg",
      Youtube: "/icons/social-media/icon-youtube.svg",
      X: "/icons/social-media/icon-x.svg",
    };

    return icons[title] || "/icons/ifood-smiley.svg";
  };

  const pharmacyInfo = merchant.isPharmacy()
    ? [
        { key: "pharmacist_name", label: "Pessoa farmacêutica responsável" },
        { key: "crf", label: "CRF" },
        { key: "operating_authorization", label: "AFE" },
        { key: "sanitary_permit", label: "CMVS" },
        { key: "sanitary_permit_expiration", label: "Vencimento" },
        { key: "special_authorization", label: "AE" },
        { key: "special_authorization_expiration", label: "Vencimento" },
      ]
        .map(({ key, label }) => {
          return (
            merchant.metadata?.[key] && `${label}: ${merchant.metadata[key]}`
          );
        })
        .filter(Boolean)
    : [];

  return (
    <S.Footer>
      <S.TopFooter>
        <S.Content>
          {Boolean(institutionalLink.length) && (
            <S.Column>
              <S.Title>Institucional</S.Title>
              {institutionalLink.map((link) => (
                <Link key={link.id} href={link.url}>
                  <S.Info>{link.title}</S.Info>
                </Link>
              ))}
            </S.Column>
          )}

          {Boolean(socialMediaLinks.length) && (
            <S.Column>
              <S.Title>Redes sociais</S.Title>
              <S.SocialMediaGroupColumn>
                {socialMediaLinks.map((link) => (
                  <Link key={link.id} href={link.url}>
                    <S.SocialMediaIcon
                      onClick={() => (window.location.href = link.url)}
                    >
                      <NextImage
                        src={getSocialMediaIcons(link.title)}
                        width={24}
                        height={24}
                      />
                    </S.SocialMediaIcon>
                  </Link>
                ))}
              </S.SocialMediaGroupColumn>
            </S.Column>
          )}

          <S.Column>
            <S.Title>Precisa de ajuda?</S.Title>
            <S.Info>+55 {merchant.phone}</S.Info>
            {merchant.getFAQLink() && (
              <Link href={merchant.getFAQLink()}>
                <S.Info>FAQ</S.Info>
              </Link>
            )}
          </S.Column>

          {payments && (
            <S.Column>
              <S.Title>Formas de pagamento</S.Title>
              <S.Payments>
                {payments.map((payment) => (
                  <NextImage
                    key={payment.id}
                    height={22}
                    width={32}
                    src={getBrandImageURL(payment)}
                    alt={payment.name}
                  />
                ))}
              </S.Payments>
            </S.Column>
          )}
        </S.Content>
      </S.TopFooter>
      <S.Divider />
      <S.BottomFooter>
        <S.Content>
          <S.Column>
            <S.Row>
              <S.PoweredBy>powered by</S.PoweredBy>
              <NextImage
                height={40}
                width={50}
                src="/icons/ifood-smiley.svg"
                alt="iFood logo"
              ></NextImage>
              <S.Copyright>
                © Copyright 2024 - iFood - Todos os direitos reservados iFood
                com Agência de Restaurantes Online S.A.
              </S.Copyright>
            </S.Row>
          </S.Column>

          <S.LgpdLinks>
            <Link href="https://static.ifood.com.br/digital-catalog/resources/termos_de_servico.pdf">
              <S.SmallInfo>Termos e condições de uso</S.SmallInfo>
            </Link>

            <Link href="https://privacidade.ifood.com.br/privacidade-clientes/">
              <S.SmallInfo>Declaração de privacidade</S.SmallInfo>
            </Link>
          </S.LgpdLinks>
        </S.Content>

        {Boolean(pharmacyInfo.length) && (
          <S.PharmaInfo>{pharmacyInfo.join(" | ")}</S.PharmaInfo>
        )}
      </S.BottomFooter>
    </S.Footer>
  );
};
