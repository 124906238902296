import { useMerchant } from '@whitelabel-webapp/merchant/shared/merchant-store';
import { snackbar } from '@whitelabel-webapp/shared/design-system';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useOrder } from '@app/domains/order';
import { orderAboyeur } from '@app/domains/order/aboyeur';

import { ERRORS } from '../../constants';
import { Order } from '../../models';
import { CancelledSummary } from '../CancelledSummary';
import { ConcludedSummary } from '../ConcludedSummary';
import { InProgressSummary } from '../InProgressSummary';
import { Rating } from '../Rating';
import { WaitingPaymentSummary } from '../WaitingPaymentSummary';
import * as S from './styles';

type ItemListProps = {
  order: Order;
};

export const ItemList: React.VFC<ItemListProps> = ({ order }) => {
  const { merchant } = useMerchant();
  const { closePixDrawer, pixDrawerStatus } = useOrder();
  const [updatedOrder, setUpdatedOrder] = useState<Order>(order);
  const shouldRunPolling = !(
    updatedOrder.lastStatus === 'CANCELLED' ||
    updatedOrder.lastStatus === 'CONCLUDED' ||
    updatedOrder.lastStatus === 'DECLINED'
  );

  useQuery(['get-order', merchant.id, order.id], () => Order.getOrder(merchant.id, order.id), {
    refetchInterval: 30000,
    enabled: shouldRunPolling,
    refetchIntervalInBackground: true,
    onSuccess: (value) => {
      const newOrder = Order.fromApi(value);
      setUpdatedOrder(newOrder);
    },
    onError: (error: Error) => {
      const message = error.message || ERRORS.UNEXPECTED;
      orderAboyeur.events.catch.onError(error);
      snackbar({ variant: 'error', message });
    },
  });

  useEffect(() => {
    if (pixDrawerStatus.order?.id === updatedOrder.id && pixDrawerStatus.isOpen && !updatedOrder.isWaitingPayment()) {
      closePixDrawer();
    }
  }, [updatedOrder, closePixDrawer, pixDrawerStatus]);

  return (
    <>
      <S.Wrapper data-testid="order">
        {updatedOrder.isConcluded() && <ConcludedSummary order={updatedOrder} />}
        {updatedOrder.isCancelled() && <CancelledSummary order={updatedOrder} />}
        {updatedOrder.isInProgress() && <InProgressSummary order={updatedOrder} />}
        {updatedOrder.isWaitingPayment() && <WaitingPaymentSummary order={updatedOrder} />}
      </S.Wrapper>
      {updatedOrder.isConcluded() && <Rating order={updatedOrder} />}
    </>
  );
};
